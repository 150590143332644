/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from "react"
import { Spinner } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import axios from "axios"
import Multiselect from "../UI/Mutidropdown"
import { navigate } from "gatsby"
// import { sheetApi } from "../../apis/index"

const ContactForm = () => {
  const enquiryForm = {
    title: "PARTNER WITH WEBDURA",
    text: "How about an easy fill in the blanks?",
    form: [
      { label: "First name", name: "Last name" },
      { label: "Last name", name: "NAME1" },
    ],
    button: "TEST3",
    nextButton: "Next",
    submitButton: "Submit",
    previousButton: "Go back",
  }

  const { language } = useSelector(state => state.global)
  const { nextButton, submitButton, previousButton } = useSelector(
    ({ contact }) => contact.formButtons
  )

  const { text, title, form } = useSelector(
    ({ contact }) => contact.enquiryForm
  )

  const [activeForm, setForm] = useState(1)
  const [isLoading, setLoader] = useState(false)
  const [selectedOptions, selectOption] = useState([])
  const [formValues, setFormvalue] = useState({
    name: "",
    email: "",
    phone: "",
    page: "Contact us",
    typeOfInquirer: "Customer",
  })
  const {
    handleSubmit,
    register,
    formState: { errors },
    trigger,
  } = useForm({ reValidateMode: "onSubmit" })

  const selectOptionHandle = e => {
    const {
      target: { value, checked },
    } = e
    const currentOptions = selectedOptions
    if (checked) {
      currentOptions.push(value)
      selectOption(currentOptions)
    } else {
      const updatedState = currentOptions.filter(item => item !== value)
      selectOption(updatedState)
    }
  }
  const handleInputChange = ({ name, value }) => {
    setFormvalue({ ...formValues, [name]: value })
  }

  const emailRegex =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  const phoneRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

  const formValidation = {
    firstName: {
      required: "First Name is Required",
    },
    lastName: {
      required: "Last Name is Required",
    },
    email: {
      required: "Email is Required",
      pattern: {
        value: emailRegex,
        message:
          language === "sv" ? "Ogiltig e-postadress" : "Invalid email address",
      },
    },
    option: {
      required: "Please select an option",
    },
    phone: {
      required: "Phone is Required",
      pattern: {
        value: phoneRegex,
        message: language === "sv" ? "Ogiltig telefon" : "Invalid Phone",
      },
    },
  }
  const formSubmit = e => {
    setLoader(true)
    const formData = new FormData()
    const updatedFormValues = {
      ...formValues,
      page: "Contact us",
      name: `${formValues.firstName} ${formValues.lastName}`,
      typeOfInquirer: "Customer",
      options: selectedOptions.join(","),
      ...JSON.parse(localStorage.getItem("utmTags")),
    }
    Object.keys(updatedFormValues).forEach(key => {
      formData.append(key, updatedFormValues[key])
    })
    const URL =
      "https://script.google.com/macros/s/AKfycbyAphKwhP3HVxKfW0j9d62eMc5eOBG6sViC4KS4bGzDxqknnrDXFwmRwmUplUPLdG_O/exec"
    return axios(URL, {
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        setLoader(false)
        navigate("/thankyou")
      })
      .catch(error => {
        console.log({ error })
      })
  }

  const formFields = {
    1: ["firstName", "lastName"],
    2: ["email", "phone"],
    3: ["option"],
  }

  const handleFormSubmit = async type => {
    if (type === "next") {
      const result = await trigger(formFields[activeForm])
      result && setForm(prevForm => prevForm + 1)
    } else if (type === "prev") {
      const result = await trigger(formFields[activeForm])
      result && setForm(prevForm => prevForm - 1)
    }
  }

  return (
    <section className="mutistep_form">
      <div className="container">
        <div className="phoneclr p-0">
          <div className="container">
            <div className="row text_side p-0">
              <div className="txt_block">
                <div className="lftbrdr">
                  <h6>{title[language]}</h6>
                  <h4>{text[language]}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form id="regForm" onSubmit={handleSubmit(formSubmit)}>
          <div
            className="tab"
            style={{ display: activeForm === 1 ? "block" : "" }}
          >
            <div className="form_flx">
              {form.slice(0, 2).map(field => {
                const fieldName = field.name
                return (
                  <div className="inp_one">
                    <label>{field.label[language]}</label>
                    <input
                      name={field.name[language]}
                      {...register(`${fieldName}`, {
                        ...formValidation[fieldName],
                        required: field.errorMessage[language],
                      })}
                      onChange={({ target }) => handleInputChange(target)}
                    />
                    <p className="invalidd" style={{ display: "block" }}>
                      {errors[field.name] && errors[field.name].message}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>

          <div
            className="tab"
            style={{ display: activeForm === 2 ? "block" : "" }}
          >
            <div className="form_flx">
              {form.slice(2, 4).map(field => {
                const fieldName = field.name
                return (
                  <div className="inp_one">
                    <label>{field.label[language]}</label>
                    <input
                      name={fieldName}
                      {...register(`${fieldName}`, {
                        ...formValidation[fieldName],
                        required: field.errorMessage[language],
                      })}
                      onChange={({ target }) => handleInputChange(target)}
                    />
                    <p className="invalidd" style={{ display: "block" }}>
                      {errors[field.name] && errors[field.name].message}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>

          <div
            className="tab"
            style={{
              display: activeForm === 3 || activeForm === 4 ? "block" : "",
            }}
          >
            <div className="form_flx">
              {form.slice(4, 6).map((field, index) => (
                <div className="inp_one">
                  <label>{field.label[language]}</label>
                  {index === 0 ? (
                    <>
                      <Multiselect
                        language={language}
                        options={field.options}
                        selectedOptions={selectedOptions}
                        onSelect={e => selectOptionHandle(e)}
                        name={field.name}
                        validation={register(`${field.name}`, {
                          ...formValidation[field.name],
                          required: field.errorMessage[language],
                        })}
                      />
                      <p className="invalidd" style={{ display: "block" }}>
                        {errors[field.name] && errors[field.name].message}
                      </p>
                    </>
                  ) : (
                    <div className="select_field">
                      <select
                        {...register(`${field.name}`, {
                          ...formValidation[field.name],
                          required: field.errorMessage[language],
                        })}
                        className="custom-select form-control"
                        name={field.name}
                        onChange={({ target }) => handleInputChange(target)}
                      >
                        {field.options.map(option => (
                          <option
                            value={option.label[language]}
                            name={field.name}
                          >
                            {option.label[language]}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="btn_field">
            <button
              type="button"
              id="prevBtn"
              style={{ display: activeForm === 1 ? "none" : "block" }}
              onClick={() => handleFormSubmit("prev")}
            >
              {previousButton[language]}
            </button>
            {activeForm !== 4 ? (
              <button
                type="button"
                id="nextBtn"
                onClick={() => handleFormSubmit("next")}
              >
                {activeForm === 3
                  ? submitButton[language]
                  : nextButton[language]}
              </button>
            ) : (
              <button type="submit" id={isLoading ? "submitBtn" : "nextBtn"}>
                {isLoading ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  <>{submitButton[language]}</>
                )}
              </button>
            )}
          </div>

          <div className="btm_indicator d-flex justify-content-center align-items-center">
            <span className={`step ${activeForm === 1 ? "active" : ""}`} />
            <span className={`step ${activeForm === 2 ? "active" : ""}`} />
            <span className={`step ${activeForm === 3 ? "active" : ""}`} />
          </div>
        </form>
      </div>
    </section>
  )
}

export default React.memo(ContactForm)
